const Envelope = () => {
  return (
    <svg
      width='72'
      height='76'
      viewBox='0 0 72 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5017_16387)'>
        <path
          d='M31.2661 2.33753L0 27.4979V68.0191C0 72.222 3.40713 75.6291 7.61002 75.6291H64.2551C68.458 75.6291 71.8651 72.222 71.8651 68.0191V27.4979L40.8275 2.35321C38.0419 0.0965266 34.0591 0.0899903 31.2661 2.33753Z'
          fill='#35383F'
        />
        <rect
          x='10.3711'
          y='9.74902'
          width='51.3666'
          height='63.9433'
          rx='9.13201'
          fill='#ED7135'
        />
        <path
          d='M14.791 19.3897C14.791 18.6567 15.3852 18.0625 16.1182 18.0625H54.7589C55.4919 18.0625 56.0861 18.6567 56.0861 19.3897C56.0861 20.1227 55.4919 20.7169 54.7589 20.7169H16.1182C15.3852 20.7169 14.791 20.1227 14.791 19.3897Z'
          fill='#EAECEF'
        />
        <path
          opacity='0.5'
          d='M14.791 28.0162C14.791 27.2832 15.3852 26.689 16.1182 26.689H54.7589C55.4919 26.689 56.0861 27.2832 56.0861 28.0162C56.0861 28.7492 55.4919 29.3434 54.7589 29.3434H16.1182C15.3852 29.3434 14.791 28.7492 14.791 28.0162Z'
          fill='#EAECEF'
        />
        <path
          opacity='0.5'
          d='M14.791 36.6431C14.791 35.9101 15.3852 35.3159 16.1182 35.3159H54.7589C55.4919 35.3159 56.0861 35.9101 56.0861 36.6431C56.0861 37.3761 55.4919 37.9703 54.7589 37.9703H16.1182C15.3852 37.9703 14.791 37.3761 14.791 36.6431Z'
          fill='#EAECEF'
        />
        <path
          opacity='0.5'
          d='M14.791 45.2706C14.791 44.5376 15.3852 43.9434 16.1182 43.9434H54.7589C55.4919 43.9434 56.0861 44.5376 56.0861 45.2706C56.0861 46.0036 55.4919 46.5978 54.7589 46.5978H16.1182C15.3852 46.5978 14.791 46.0036 14.791 45.2706Z'
          fill='#EAECEF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M0 27.4297V68.0194C0 72.2223 3.40712 75.6294 7.61001 75.6294H43.7647C51.1692 75.6294 54.2126 66.1274 48.186 61.8255L0 27.4297Z'
          fill='url(#paint0_linear_5017_16387)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M71.8652 27.4297V68.0194C71.8652 72.2223 68.4581 75.6294 64.2552 75.6294H6.20027L71.8652 27.4297Z'
          fill='url(#paint1_linear_5017_16387)'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M72.0011 28.0547L8.67804 75.6297H6.03516L71.2224 26.9824L72.0011 28.0547Z'
          fill='#161718'
        />
        <rect
          y='-7'
          width='72'
          height='83'
          fill='url(#paint2_linear_5017_16387)'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_5017_16387'
          x1='8.77757e-07'
          y1='27.3523'
          x2='49.4384'
          y2='82.7631'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7E7E7' />
          <stop offset='1' stopColor='#A0A0A0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_5017_16387'
          x1='71.8652'
          y1='27.3523'
          x2='22.289'
          y2='81.3877'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7E7E7' />
          <stop offset='1' stopColor='#A0A0A0' />
        </linearGradient>
        <linearGradient
          id='paint2_linear_5017_16387'
          x1='36'
          y1='-7'
          x2='36'
          y2='76'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#1F2021' stopOpacity='0' />
          <stop offset='1' stopColor='#1F2021' stopOpacity='0.9' />
        </linearGradient>
        <clipPath id='clip0_5017_16387'>
          <rect width='72' height='76' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Envelope;
