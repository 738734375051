import { useUser } from '~/context/UserContext';
import Google from '~/icons/Google';
import GreenCheck from '~/icons/GreenCheck';

import { Form, NavLink } from '@remix-run/react';

import styles from './SignUpCard.module.scss';

const SignUpCard = () => {
  const { user } = useUser();

  if (user) {
    return <></>;
  }

  return (
    <div className={styles.card}>
      <h3 className={styles.heading}>Explore Your Interests</h3>
      <div className={styles.description}>
        <p>
          Create an account and enjoy content that interests you with your
          personalized feed
        </p>
      </div>
      <div className={styles.bonuses}>
        <div className={styles.bonus}>
          <GreenCheck />
          Unlimited Access
        </div>
        <div className={styles.bonus}>
          <GreenCheck />
          Personalized Feed
        </div>
        <div className={styles.bonus}>
          <GreenCheck />
          Full Experience
        </div>
      </div>
      <div className={styles.socialButtons}>
        <Form method='post' action='/login'>
          <button
            type='submit'
            name='provider'
            value='google'
            className={styles.socialButton}
          >
            <Google />
            Continue with Google
          </button>
        </Form>
      </div>
      <div className={styles.note}>
        By continuing, you agree to the{' '}
        <NavLink to='/privacy-policy'>Privacy Policy.</NavLink>. You also agree
        to receive our newsletters, you can opt-out any time.
      </div>
    </div>
  );
};

export default SignUpCard;
