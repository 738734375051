import { useUser } from '~/context/UserContext';
import Envelope from '~/icons/Envelope';

import { NavLink } from '@remix-run/react';

import styles from './SubscribeCard.module.scss';

const SubscribeCard = () => {
  const { user } = useUser();

  if (user?.mailer_lite_groups.length) return <></>;

  return (
    <div className={styles.card}>
      <Envelope />
      <h3 className={styles.heading}>Stay Informed - Subscribe Newsletter</h3>
      <div className={styles.description}>
        Receive exclusive insights, updates, and stories delivered straight to
        your inbox every week!
      </div>
      <NavLink className={styles.link} to='/user-profile/newsletter'>
        Subscribe
      </NavLink>
    </div>
  );
};

export default SubscribeCard;
